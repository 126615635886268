import React from "react";

interface AboutInterface {
  // Define your interface properties here
}

const About: React.FC<AboutInterface> = () => {
  const members = [
    "member-1.jpg",
    "member-2.jpg",
    "member-3.jpg",
    "member-4.jpg",
    "member-5.jpg",
  ];

  const renderMembers = () =>
    members.map((member, index) => (
      <div
        key={index}
        className='member-item set-bg'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/team-member/${member})`,
        }}
      >
        <div className='mi-social'>
          <div className='mi-social-inner bg-gradient'>
            <a href='#'>
              <i className='fa fa-facebook'></i>
            </a>
            <a href='#'>
              <i className='fa fa-instagram'></i>
            </a>
            <a href='#'>
              <i className='fa fa-twitter'></i>
            </a>
            <a href='#'>
              <i className='fa fa-linkedin'></i>
            </a>
          </div>
        </div>
        <div className='mi-text'>
          <h5>Emma Sandoval</h5>
          <span>Speaker</span>
        </div>
      </div>
    ));
  return (
    <>
      <section className='breadcrumb-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb-text'>
                <h2>About Us</h2>
                <div className='bt-option'>
                  <a href='#'>Home</a>
                  <span>About</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='about-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Something About Us</h2>
                <p className='f-para'>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='about-pic'>
                <img src='img/banner2.jpg' alt='' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='ha-text'>
                <h2>About Conference</h2>
                <p>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>
                <ul>
                  <li>
                    <span className='icon_check'></span> Marching Frogs is a
                    network of Legal Professionals.
                  </li>
                  <li>
                    <span className='icon_check'></span> Provides timely,
                    relevant, and unique events Globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Helps connect with the
                    right knowledge, ideas, people, marketplaces, and
                    opportunities.
                  </li>
                  <li>
                    <span className='icon_check'></span> Legal counsel advice
                    intends to cover every aspect of legal matters globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Aims to provide the
                    best legal platform in the world
                  </li>
                </ul>
                <a href='#' className='ha-btn'>
                  Discover Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='team-member-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Who’s speaking</h2>
                <p>
                  These are our communicators, you can see each person's
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>
        {renderMembers()}
      </section> */}

      <section className='story-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Our Story</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='story-left'>
                <div className='story-item'>
                  <h2>2008</h2>
                  <div className='si-text'>
                    <h4>Adwords Keyword Research For Beginners</h4>
                    <p>
                      However this is also the most expensive position. Give it
                      a try if the second to fourth display position gives you
                      more visitors and more customers for less money.
                    </p>
                  </div>
                </div>
                <div className='story-item'>
                  <h2>2011</h2>
                  <div className='si-text'>
                    <h4>Adwords Keyword Research For Beginners</h4>
                    <p>
                      Virgin Mobile took a more effective approach in marketing
                      their cell phone service by focusing not on the people
                      that would be making the actual purchase.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='story-right'>
                <div className='story-item'>
                  <h2>2015</h2>
                  <div className='si-text'>
                    <h4>15 Tips To Increase Your Adwords Profits</h4>
                    <p>
                      There is no better advertisement campaign that is low cost
                      and also successful at the same time. Great business ideas
                      when utilized effectively can save lots of money.
                    </p>
                  </div>
                </div>
                <div className='story-item'>
                  <h2>2019</h2>
                  <div className='si-text'>
                    <h4>
                      Advertising Internet Online Opportunities To Explore
                    </h4>
                    <p>
                      Many people sign up for affiliate programs with the hopes
                      of making some serious money. They advertise a few places
                      and then wait for the money to start pouring in.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className='pricing-section set-bg spad'
        data-setbg='img/pricing-bg.jpg'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Registration options</h2>
                <p>Get your event ticket plan</p>
              </div>
            </div>
          </div>
          <section className='bg-white dark:bg-gray-900 text-gray-800'>
            <div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
              <div className='mx-auto max-w-screen-md text-center mb-8 lg:mb-12'>
                <h2 className='mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-black'>
                  Pricing of our events
                </h2>
              </div>
              <div className='space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0'>
                <div className='flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white'>
                  <h3 className='mb-4 text-2xl font-semibold'>
                    Premium Delegate Pass
                  </h3>

                  <div className='flex justify-center items-baseline my-8'>
                    <span className='mr-2 text-5xl text-black font-extrabold'>
                      $699
                    </span>
                  </div>
                  <ul
                    role='list'
                    className='mb-8 space-y-4 text-left text-black'
                  >
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complete access to all technical sessions during the
                        annual conference will be granted.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Conference badges and materials will be distributed to
                        participants.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        An e-Certificate of participation will be issued after
                        the event, upon request.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complimentary access to the ILC Networking Chat.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Participants will have access to lunch, the networking
                        zone, and tea breaks throughout all three days of the
                        conference.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Registration may be transferred to another colleague
                        within the same organization.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Marketing benefits like profile will be published in our
                        annual magazine
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Access to all attendees list post the conference
                      </span>
                    </li>
                  </ul>
                  <button className='text-black border-2 rounded px-3 py-2 font-bold border-black'>
                    Get Started
                  </button>
                </div>
                <div className='flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white'>
                  <h3 className='mb-4 text-2xl font-semibold'>Speaker Pass</h3>

                  <div className='flex justify-center items-baseline my-8'>
                    <span className='mr-2 text-5xl font-extrabold text-black'>
                      $599
                    </span>
                  </div>
                  <ul
                    role='list'
                    className='mb-8 space-y-4 text-left text-black'
                  >
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>Speaking opportunity in any chosen session</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complete access to all technical sessions during the
                        annual conference will be granted.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Conference badges and materials will be distributed to
                        participants.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        An e-Certificate of participation will be issued after
                        the event, upon request.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complimentary access to the ILC Networking Chat.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Participants will have access to lunch, the networking
                        zone, and tea breaks throughout all three days of the
                        conference.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Registration may be transferred to another colleague
                        within the same organization.
                      </span>
                    </li>
                  </ul>
                  <button className='text-black border-2 rounded px-3 py-2 font-bold border-black'>
                    Get Started
                  </button>
                </div>
                <div className='flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white'>
                  <h3 className='mb-4 text-2xl font-semibold'>
                    Exhibition Booth
                  </h3>

                  <div className='flex justify-center items-baseline my-8'>
                    <span className='mr-2 text-5xl font-extrabold text-black'>
                      $1999
                    </span>
                  </div>
                  <ul
                    role='list'
                    className='mb-8 space-y-4 text-left text-black'
                  >
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>Exhibition Booth</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>Exhibitors marketing benefits</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>All attendees contact list post conference</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>3 delegate passes</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complete access to all technical sessions during the
                        annual conference will be granted.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Conference badges and materials will be distributed to
                        participants.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        An e-Certificate of participation will be issued after
                        the event, upon request.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complimentary access to the ILC Networking Chat.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Participants will have access to lunch, the networking
                        zone, and tea breaks throughout all three days of the
                        conference.
                      </span>
                    </li>
                  </ul>
                  <button className='text-black border-2 rounded px-3 py-2 font-bold border-black'>
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default About;
