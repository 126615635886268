import React from "react";
import FutureEvent from "../FutureEvent";

interface HomeInterface {
  // Define your interface properties here
}

const Home: React.FC<HomeInterface> = () => {
  const sessions1 = [
    {
      id: 1,
      title:
        "Litigation: Enforcement of Foreign Judgments in the Middle East Reason",
    },
    { id: 2, title: "Does Corporate Matter, Problems with Outsourcing" },
    {
      id: 3,
      title:
        "The Legal Challenges And Disputes That Arise In The Context Of Infrastructure Development Projects",
    },
    {
      id: 4,
      title:
        "The Legal Issues Of The Metaverse And NFTs - The Good, The Brag, And The Ugly",
    },
    {
      id: 5,
      title:
        "Strategies For Managing Litigation Risk, Including Insurance, Litigation Financing, And Early Case Assessment",
    },
  ];
  const sessions2 = [
    {
      id: 6,
      title:
        "Digital Transformation Technologies Of Legal Proceedings In Civil And Administrative Cases",
    },
    {
      id: 7,
      title:
        "The Use Of AI In Litigation: Legal And Ethical Concerns, For Example Bias In AI Algorithms",
    },
    {
      id: 8,
      title:
        "The Recent Reforms To The Saudi Legal System And Their Impact On Litigation",
    },
    {
      id: 9,
      title:
        "ADR (Alternative Dispute Resolution) And Litigation - Two Different Approaches To Resolving Legal Disputes",
    },
    {
      id: 10,
      title:
        "The Arbitral Process And Alternative Dispute Resolution In The EMEA Region",
    },
    { id: 11, title: "Diversity, Equity, and Inclusion in Litigation" },
    {
      id: 12,
      title: "Future Trends in Commercial and Investment Arbitration",
    },
  ];
  const members = [
    "member-1.jpg",
    "member-2.jpg",
    "member-3.jpg",
    "member-4.jpg",
    "member-5.jpg",
  ];

  const renderMembers = () =>
    members.map((member, index) => (
      <div
        key={index}
        className='member-item set-bg'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/team-member/${member})`,
        }}
      >
        <div className='mi-social'>
          <div className='mi-social-inner bg-gradient'>
            <a href='#'>
              <i className='fa fa-facebook'></i>
            </a>
            <a href='#'>
              <i className='fa fa-instagram'></i>
            </a>
            <a href='#'>
              <i className='fa fa-twitter'></i>
            </a>
            <a href='#'>
              <i className='fa fa-linkedin'></i>
            </a>
          </div>
        </div>
        <div className='mi-text'>
          <h5>Emma Sandoval</h5>
          <span>Speaker</span>
        </div>
      </div>
    ));
  return (
    <>
      <section
        className='hero-section set-bg object-fill'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/banner1.jpg)`,
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='hero-text'>
                <span>06 & 07 May 2025, Downtown hotel, Dubai</span>
                <h2>International Legal Symposium</h2>
                <a href='#' className='primary-btn'>
                  Buy Ticket
                </a>
              </div>
            </div>
            <div className='col-lg-5'>
              <img src='img/hero-right.png' alt='' />
            </div>
          </div>
        </div>
      </section>
      <section className='counter-section bg-gradient'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'></div>
            <div className='col-lg-8'>
              <div className='cd-timer' id='countdown'>
                <div className='cd-item'>
                  <span>40</span>
                  <p>Days</p>
                </div>
                <div className='cd-item'>
                  <span>18</span>
                  <p>Hours</p>
                </div>
                <div className='cd-item'>
                  <span>46</span>
                  <p>Minutes</p>
                </div>
                <div className='cd-item'>
                  <span>32</span>
                  <p>Seconds</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='home-about-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='ha-pic'>
                <img src='img/banner2.jpg' alt='' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='ha-text'>
                <h2>About Conference</h2>
                <p>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>
                <ul>
                  <li>
                    <span className='icon_check'></span> Marching Frogs is a
                    network of Legal Professionals.
                  </li>
                  <li>
                    <span className='icon_check'></span> Provides timely,
                    relevant, and unique events Globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Helps connect with the
                    right knowledge, ideas, people, marketplaces, and
                    opportunities.
                  </li>
                  <li>
                    <span className='icon_check'></span> Legal counsel advice
                    intends to cover every aspect of legal matters globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Aims to provide the
                    best legal platform in the world
                  </li>
                </ul>
                <a href='#' className='ha-btn'>
                  Discover Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='team-member-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Who’s speaking</h2>
                <p>
                  These are our communicators, you can see each person's
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>
        {renderMembers()}
      </section> */}
      <section className='schedule-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Agenda and sessions</h2>
                <p>Do not miss anything topic about the event</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='schedule-tab'>
                <ul className='nav nav-tabs grid grid-cols-2' role='tablist'>
                  <li className='nav-item w-full text-center'>
                    <a
                      className='nav-link active'
                      data-toggle='tab'
                      href='#tabs-1'
                      role='tab'
                    >
                      <h5>Day 1</h5>
                      <p>March 04, 2025</p>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      data-toggle='tab'
                      href='#tabs-2'
                      role='tab'
                    >
                      <h5>Day 2</h5>
                      <p>March 05, 2025</p>
                    </a>
                  </li>
                </ul>
                <div className='tab-content'>
                  <div
                    className='max-w-6xl mx-auto p-8 bg-gray-100 rounded-lg shadow-lg tab-pane'
                    id='tabs-1'
                    role='tabpanel'
                  >
                    <h1 className='text-3xl font-bold text-center mb-8'>
                      Agenda 2025 - March
                    </h1>
                    <table className='min-w-full bg-white rounded-lg'>
                      <thead>
                        <tr>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Session
                          </th>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Topic
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        {sessions1.map((session) => (
                          <tr key={session.id} className='hover:bg-gray-100'>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm font-medium text-gray-900'>
                              {`Session ${session.id}`}
                            </td>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm text-gray-700'>
                              {session.title}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='max-w-6xl mx-auto p-8 bg-gray-100 rounded-lg shadow-lg tab-pane'
                    id='tabs-2'
                    role='tabpanel'
                  >
                    <h1 className='text-3xl font-bold text-center mb-8'>
                      Agenda 2025 - March
                    </h1>
                    <table className='min-w-full bg-white rounded-lg'>
                      <thead>
                        <tr>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Session
                          </th>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Topic
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        {sessions2.map((session) => (
                          <tr key={session.id} className='hover:bg-gray-100'>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm font-medium text-gray-900'>
                              {`Session ${session.id}`}
                            </td>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm text-gray-700'>
                              {session.title}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='tab-pane' id='tabs-3' role='tabpanel'>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-1.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Dealing with Intermediate People</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-2.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>V7 Digital Photo Printing</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-3.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Beyond The Naked Eye</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-4.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Influencing The Influencer</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='tabs-4' role='tabpanel'>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-1.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Dealing with Expert People</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-2.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>V7 Digital Photo Printing</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-3.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Beyond The Naked Eye</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-4.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Influencing The Influencer</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='tabs-5' role='tabpanel'>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-1.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Dealing with Too Difficult People</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-2.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>V7 Digital Photo Printing</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-3.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Beyond The Naked Eye</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-4.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Influencing The Influencer</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FutureEvent />
      <section
        className='pricing-section set-bg spad'
        data-setbg='img/pricing-bg.jpg'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/pricing-bg.jpg)`,
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Registration options</h2>
                <p>Get your event ticket plan</p>
              </div>
            </div>
          </div>
          <section className=' dark:bg-gray-900 text-gray-800'>
         
              <div className='space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0'>
                <div className='flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white'>
                  <h3 className='mb-4 text-2xl font-semibold'>
                    Premium Delegate Pass
                  </h3>

                  <div className='flex justify-center items-baseline my-8'>
                    <span className='mr-2 text-5xl text-black font-extrabold'>
                      $699
                    </span>
                  </div>
                  <ul
                    role='list'
                    className='mb-8 space-y-4 text-left text-black'
                  >
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complete access to all technical sessions during the
                        annual conference will be granted.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Conference badges and materials will be distributed to
                        participants.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        An e-Certificate of participation will be issued after
                        the event, upon request.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complimentary access to the ILC Networking Chat.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Participants will have access to lunch, the networking
                        zone, and tea breaks throughout all three days of the
                        conference.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Registration may be transferred to another colleague
                        within the same organization.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Marketing benefits like profile will be published in our
                        annual magazine
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Access to all attendees list post the conference
                      </span>
                    </li>
                  </ul>
                  <button className='text-black border-2 rounded px-3 py-2 font-bold border-black'>
                    Get Started
                  </button>
                </div>
                <div className='flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white'>
                  <h3 className='mb-4 text-2xl font-semibold'>Speaker Pass</h3>

                  <div className='flex justify-center items-baseline my-8'>
                    <span className='mr-2 text-5xl font-extrabold text-black'>
                      $599
                    </span>
                  </div>
                  <ul
                    role='list'
                    className='mb-8 space-y-4 text-left text-black'
                  >
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>Speaking opportunity in any chosen session</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complete access to all technical sessions during the
                        annual conference will be granted.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Conference badges and materials will be distributed to
                        participants.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        An e-Certificate of participation will be issued after
                        the event, upon request.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complimentary access to the ILC Networking Chat.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Participants will have access to lunch, the networking
                        zone, and tea breaks throughout all three days of the
                        conference.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Registration may be transferred to another colleague
                        within the same organization.
                      </span>
                    </li>
                  </ul>
                  <button className='text-black border-2 rounded px-3 py-2 font-bold border-black'>
                    Get Started
                  </button>
                </div>
                <div className='flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white'>
                  <h3 className='mb-4 text-2xl font-semibold'>
                    Exhibition Booth
                  </h3>

                  <div className='flex justify-center items-baseline my-8'>
                    <span className='mr-2 text-5xl font-extrabold text-black'>
                      $1999
                    </span>
                  </div>
                  <ul
                    role='list'
                    className='mb-8 space-y-4 text-left text-black'
                  >
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>Exhibition Booth</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>Exhibitors marketing benefits</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>All attendees contact list post conference</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>3 delegate passes</span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complete access to all technical sessions during the
                        annual conference will be granted.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Conference badges and materials will be distributed to
                        participants.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        An e-Certificate of participation will be issued after
                        the event, upon request.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Complimentary access to the ILC Networking Chat.
                      </span>
                    </li>
                    <li className='flex items-center space-x-3'>
                      <svg
                        className='flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                      <span>
                        Participants will have access to lunch, the networking
                        zone, and tea breaks throughout all three days of the
                        conference.
                      </span>
                    </li>
                  </ul>
                  <button className='text-black border-2 rounded px-3 py-2 font-bold border-black'>
                    Get Started
                  </button>
                </div>
              </div>
           
          </section>
        </div>
      </section>
    </>
  );
};

export default Home;
