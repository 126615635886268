import React from "react";

const FutureEvent = () => {
  return (
    <div className='pb-10'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='section-title'>
            <h2>Future Event</h2>
            <p>Do not miss our future event</p>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-3 gap-5 px-6'>
        <section className='relative w-full'>
          <img src='/img/future/riyaad.jpg' alt='riyaad' className='rounded' />
          <div className='flex flex-col items-center justify-center absolute bg-white top-16 left-[20%] p-4 rounded'>
            <h3 className='text-xl font-bold'> EVENT Riyaad 2025</h3>
            <h3 className='text-lg font-semibold'>24 JUNE - 25 JUNE 2025</h3>
            <h3>Hotel Riyaad</h3>
            <h3>Riyaad</h3>
          </div>
        </section>
        <section className='relative w-full'>
          <img src='/img/future/hungary.jpg' alt='riyaad' className='rounded' />
          <div className='flex flex-col items-center justify-center absolute bg-white top-16 left-[20%] p-4 rounded'>
            <h3 className='text-xl font-bold'>EVENT Hungary 2025</h3>
            <h3 className='text-lg font-semibold'>09 SEP - 10 SEP 2025</h3>
            <h3>To be announced...</h3>
            <h3>Budapest, Hungary</h3>
          </div>
        </section>
        <section className='relative w-full'>
          <img src='/img/future/riyaad.jpg' alt='riyaad' className='rounded' />
          <div className='flex flex-col items-center justify-center absolute bg-white top-16 left-[20%] p-4 rounded'>
            <h3 className='text-xl font-bold'>EVENT India 2025</h3>
            <h3 className='text-lg font-semibold'>25 AUG - 26 NOV 2025</h3>
            <h3>To be announced ...</h3>
            <h3>INDIA</h3>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FutureEvent;
